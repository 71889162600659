





























































































import {Component, Vue, Watch} from 'vue-property-decorator';
import ContractorService from "@/components/payroll/business/employments/contractors/ContractorService";
import {CounterpartyType} from "@/constants/CounterpartyType";
import Workspaces from "@/state/Workspaces";
import {IContractor} from "@/components/payroll/business/employments/contractors/Contractor";
import {namespace} from "vuex-class";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import PayrollService from "@/services/payroll/PayrollService";
import Form1099DTO from "@/dto/payroll/Form1099DTO";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {Upload1099FormPayload} from "@/dto/payroll/Upload1099FormPayload";
import {Form1099Type} from "@/constants/PayrollConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import {ContractorFilter} from "@/dto/payroll/Filters";

const AppModule = namespace("App");

@Component({
  computed: {
	  SelectOption() {
		  return SelectOption
	  },
    CounterpartyType() {
      return CounterpartyType
    },
    Form1099Type() {
      return Form1099Type
    }
  },
  components: {PortalInput, SingleFileHolder, PortalSelect}
})
export default class Forms1099CustomerView extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  selectedContractorType: CounterpartyType | null | 'ANONYMOUS' = null;
  contractors: Array<IContractor> = [];
  selectedContractor: IContractor | null = null;

  selectOptions: Array<SelectOption> = [];

  company1099: Array<Form1099DTO> = [];
  forms: Array<Form1099DTO> = [];

  payload = new Upload1099FormPayload();
  uploadedFile: FileMetaDTO | null = null;

  @Watch("selectedContractorType")
  onSelectedContractorTypeChanged(newType: CounterpartyType | null | 'ANONYMOUS', oldType: CounterpartyType | null | 'ANONYMOUS'){
    if(newType == 'ANONYMOUS') {
      this.contractors = [];
      this.loadForms()
    } else if(newType){
      this.forms = [];
      this.loadContractors();
    }else{
      this.contractors = [];
    }
  }


  public mounted(): void {
	  PayrollService.getForms1099(CounterpartyType.COMPANY, this.$wss.getCurrent.id!).then(
		  ok => {
			  this.company1099 = ok.data;
			  this.stopLoading();
		  },
		  err => {
			  this.stopLoading();
			  console.log(JSON.stringify(err));
		  }
	  )
  }

  loadContractors() {
    if(!this.selectedContractorType || this.selectedContractorType == 'ANONYMOUS'){
      return;
    }
    this.startLoading();
	const filter = new ContractorFilter();
	filter.employerId = Workspaces.getCurrent.id
	filter.contractorType = this.selectedContractorType;
    ContractorService.getAllByFilter(filter).then(
        ok => {
          this.contractors = ok.data.data;
          this.updateOptions();
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  updateOptions() {
    this.selectedContractor = null;
    this.selectOptions = this.contractors.map(c => SelectOption.builder().title(c.details.name).value(c).build());
  }

  @Watch("selectedContractor")
  onSelectedContractorChanged(newContractor: IContractor | null, oldContractor: IContractor | null) {
    if (newContractor) {
      this.loadForms();
    } else {
      this.forms = [];
    }
    this.payload = new Upload1099FormPayload();
    this.uploadedFile = null;
  }

  loadForms() {
    if (!this.selectedContractor && this.selectedContractorType != 'ANONYMOUS') {
      return;
    }
    this.startLoading();
    PayrollService.getForms1099(
        this.selectedContractorType == 'ANONYMOUS' ? null : (this.selectedContractor!.counterpartyType),
        this.selectedContractor?.details?.id,
        Workspaces.getCurrent.id).then(
        ok => {
          this.forms = ok.data;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )

  }

  uploadForm() {
    if ((!this.selectedContractor && this.selectedContractorType != 'ANONYMOUS') || !this.uploadedFile || !this.payload.type || !this.payload.year) {
      return;
    }
    this.payload.fileId = this.uploadedFile.id;
    if(this.selectedContractorType != 'ANONYMOUS') {
      this.payload.contractorType = this.selectedContractor!.counterpartyType!;
      this.payload.contractorId = this.selectedContractor!.id!;
    } else {
      this.payload.contractorType = null;
      this.payload.contractorId = null;
      this.payload.companyId = Workspaces.getCurrent.id;
    }
    this.startLoading();
    PayrollService.saveForm1099(this.payload).then(
        ok => {
          this.stopLoading();
          this.payload = new Upload1099FormPayload();
          this.uploadedFile = null;
          this.loadForms();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

}
